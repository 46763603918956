import ArticlePreview from '@components/article-preview'
import Layout from '@container/layout'
import { graphql, Link } from 'gatsby'
import React from 'react'
import { Col, Container, Pagination, Row } from 'react-bootstrap'
import Helmet from 'react-helmet'

const BlogListTemplate = ({ data, pageContext }) => {
  const seo = data.allContentfulGlobalSeo.edges[0].node
  const blogListTitleBase =
    data.allContentfulGlobalSeo.edges[0].node.blogListSeoTitle
  const blogListTitle = blogListTitleBase
    ? blogListTitleBase
    : seo.globalSeoTitle
  const blogListDescriptionBase = `${data.allContentfulGlobalSeo.edges[0].node.blogListSeoDescription}`
  const blogListDescription = blogListDescriptionBase
    ? blogListDescriptionBase
    : seo.globalSeoDescription
  const posts = data.allContentfulBlogPost.edges
  return (
    <Layout>
      <Helmet
        title={blogListTitle}
        meta={[{ name: 'description', content: `${blogListDescription}` }]}
      />
      <header>
        <Container>
          <h2 className="text-white text-center font-weight-bold pt-5">
            ブログ一覧
          </h2>
          <div className="d-flex justify-content-end">
            <Link to="/" className="text-white mb-0">
              トップページへ戻る
            </Link>
          </div>
          <hr className="mt-0 w-100" />
        </Container>
      </header>

      <Container className="mb-5">
        <Row>
          {posts.map(({ node, index }) => {
            return (
              <ArticlePreview
                article={node}
                i={index}
                key={`blog-list-${node.slug}`}
              ></ArticlePreview>
            )
          })}
        </Row>
        <Row>
          <Col className="d-flex justify-content-center">
            <Pagination>
              {[...Array(pageContext.numPages)].map((_, i) => {
                if (i === 0) {
                  return (
                    <Pagination.Item href={`/blog`}>{i + 1}</Pagination.Item>
                  )
                } else {
                  return (
                    <Pagination.Item href={`/blog/${i + 1}`}>
                      {i + 1}
                    </Pagination.Item>
                  )
                }
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default BlogListTemplate

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulGlobalSeo {
      edges {
        node {
          globalSeoTitle
          globalSeoDescription
          tagListSeoTitle
          tagListSeoDescription
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [updatedAt], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          createdAt(formatString: "YYYY-MM-DD")
          updatedAt(formatString: "YYYY-MM-DD")
          tag {
            name
          }
          thumbnail {
            fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
